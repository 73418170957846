// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";
import slugify from "slugify";
// Import FilePond styles
import "filepond/dist/filepond.min.css";  //carregar na propria pagina

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

import PlanoFamiliaAPI from '../../services/PlanoFamiliaAPI';

import Common from "../../services/common/Common";

// Create component
const FilePond = vueFilePond(
    FilePondPluginFileValidateType,
);


setOptions({
    labelIdle: '<span class="filepond--label-action"> Browse </span>',
    imageCropAspectRatio: null,
    imageEditInstantEdit: false,
    credits: false,
    allowRevert: true,
    instantUpload: true,
    allowProcess: false,
});

export default {
    name: "FileUploader",
    props: {
        label: {
            type: String
        },
        id: {
            type: String,
            default() {
                return null;
            },
        },
        cpf: {
            type: String,
            default() {
                return null;
            },
        },
        protocolo: {
            type: String,
            default() {
                return null;
            },
        }, 
        apiurl: {
            type: String,
            default() {
                return null;
            },
        },
        autoUpload: {
            type: Boolean,
            default() {
                return false;
            },
        },
    },
    data: function () {
        return {
            hasFile: false,
            anexos: null,
            wasUploaded: false,
            // fake server to simulate loading a 'local' server file and processing a file
            myServer: {
                fetch: null,
                revert: null,
                process: (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {

                    console.log(transfer);
                    console.log(options);
                    const reader = new FileReader();
                    reader.onloadend =  () => {
                        var obj = {};
                        obj.cpf = this.cpf;
                        obj.protocolo = this.protocolo;
                        obj.anexos = [];
                        var base64result = reader.result.substr(reader.result.indexOf(',') + 1);

                        obj.anexos.push({
                            descricao: slugify(file.name, '_'),
                            base64: base64result
                        });

                         PlanoFamiliaAPI.postAnexos(obj).then( () => {
                             load("Ok");
                             this.wasUploaded = true;
                            this.$refs.pond.removeFiles();
                        })
                             .catch(response => {
                                 this.wasUploaded = false;
                                error(response.response.data.messageErro);
                                Common.notifyError(null, response.response.data.messageErro);
                                this.$refs.pond.removeFiles();
                            });


                      

                    };
                    reader.readAsDataURL(file);

                    return {
                        abort: () => {
                            // This function is entered if the user has tapped the cancel button
                            // request.abort();

                            // Let FilePond know the request has been cancelled
                            abort();
                        }
                    };
                }
            },
            myFiles: null,
        };
    },
    methods: {
        handleFileAdded: function () {

            this.hasFile = true;


            if (this.autoUpload)
                this.process();
        },
        handleFileRemoved: function () {
            this.hasFile = false;

            if (this.wasUploaded) {
                this.$emit('processed', null); 
            }
        },
        process: async function () {
            if (this.hasFile) {
                await this.$refs.pond.processFile().then(() => {
                });
            }
            else {
                this.$emit('processed', null);
            }

        },
        handleFilePondInit: async function () {
            // FilePond instance methods are available on `this.$refs.pond`

            /* eslint-disable */
            // console.log("FilePond has initialized");

        },
    },
    mounted() {
    },
    components: {
        FilePond
    },
};

