<template>
    <div>
        <transition name="slide-up">
           <div class="content-container content-half" v-if="isActive">
                <div class="">
                    <div class="content-title">
                        <div class="fs-32px fw-700 color-dark mb-2">Agora falta pouco para você concluir sua adesão.</div>
                        <div class="fs-18px fw-400 color-dark mb-5" v-if="fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">
                            <b>Envie uma cópia legível de um documento de identificação com foto e CPF do(a) {{getPessoa.nome.split(" ")[0].initCap()}} e do seu representante legal. Anexe frente e verso do documento (se houver).</b>
                        </div>
                        <div class="fs-18px fw-400 color-dark mb-5" v-else>
                            <b>Envie uma cópia legível de um documento de identificação com foto e CPF. Anexe frente e verso do documento (se houver).</b>
                        </div>
                        <div class="fs-18px fw-400 color-dark mb-5">
                            Formatos de envio: PDF, JPEG ou PNG
                        </div>
                        <div class="div-warning" v-if="fluxo=='convidadomenor18' || fluxo=='aberturamenor18'">
                            <v-icon color="orange" class="f-l">info_outline</v-icon>
                            <div class="fs-20px color-dark f-l ml-2">OBS: Em caso de participante menor de idade será aceita Certidão de Nascimento e CPF</div>
                        </div>
                        <div class="mb-5">
                            <file-uploader v-if="this.pessoa!=null" ref="MainImageUploader" :cpf="this.pessoa['cpf']" :id="this.pessoa['id']" :protocolo="this.pessoa['detalhesDaAdesao'].protocolo" label="Documentos"
                                           @processed="reloadFiles($event)" auto-upload></file-uploader>
                            <p class="color-white">.</p>
                            <div class="mt-3">
                                <div class="row" v-if="this.anexosComp!=null && this.anexosComp.length>0">
                                    <div class="col-6" v-for="(item) in this.anexosComp" :key="item.titulo">
                                        <div class="row p-2">
                                            <div class="col-lg-1 col-2">
                                                <button type="button" @click="deletefile(item)" v-show="true" class="mr-button mr-3">
                                                    <img src="/img/lixeira.svg" />
                                                </button>
                                            </div>
                                            <div class="col-lg-11 col-10">
                                                <div class="fs-18px fw-400 color-dark">
                                                    {{item.titulo}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-else>
                                    <div class="col-12">
                                        <label class="form-check-label fs-24px fw-500 mb-3">
                                            Nenhum arquivo anexado.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-buttons">
                        <div class="div-warning mb-5" v-if="hasErrors && (this.anexosComp==null || this.anexosComp.length==0)">
                            <v-icon color="orange" class="f-l">info_outline</v-icon>
                            <div class="fs-20px color-dark f-l ml-2">Você precisa anexar o(s) documento(s).</div>
                        </div>
                        <div class="d-flex align-items-center justify-content-end flex-column-mobile btns-termos">
                            <button @click="next" class="button-primary-bg-primary action-button">
                                Avançar
                                <v-icon right>east</v-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'bootstrap';
    import StepTracker from "../../services/StepTracker";
    import FileUploader from '../../plugins/file-uploader/FileUploader.vue';
    import PlanoFamiliaAPI from '../../services/PlanoFamiliaAPI';
    import Common from "../../services/common/Common";

    export default {
        name: 's09-cadastrar-beneficiario-2',
        props: ['pessoa', 'visible', 'fluxo', 'number'],
        components: {
            'file-uploader': FileUploader
        },
        computed: {
            anexosComp() {
                if (this.anexos == null)
                    return null;
                var ret = [];
                for (var i = 0; i < this.anexos.length; i++) {
                    if (this.anexos[i]['tipo-documento'] == 'Documento com Foto') {
                        ret.push(this.anexos[i]);
                    }
                }
                return ret;
            },
            getPessoa() {
                return this.pessoa
            },
        },
        data: () => ({
            isActive: false,
            isReady: false,
            hasErrors: false,
            selection: 1,
            step: 1,
            rules: {
            },
            trackMsg: null,
            anexos: null
        }),
        watch: {
            visible: function () {
                this.toggle();
            },
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.habilitaOpcoes();
                }
                this.trackMsg = null;
            },
            next: function (event) {
                event.preventDefault();
                if (this.anexosComp == null || this.anexosComp.length == 0) {
                    this.hasErrors = true;
                    return false;
                }
                else
                    this.hasErrors = false;

                this.pessoa.anexos = this.anexos;

                StepTracker.registerStep(this.number, this.trackMsg);
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            },
            async habilitaOpcoes() {
                this.isReady = true;
                this.anexos = this.pessoa.anexos;
            },
            async deletefile(o) {
                var obj = {};
                obj.cpf = this.pessoa.cpf;
                obj.protocolo = this.pessoa['detalhesDaAdesao'].protocolo;
                obj.anexos = [];
                obj.anexos.push({
                    descricao: o.titulo,
                });
                await PlanoFamiliaAPI.deleteAnexos(obj).then(async () => {
                    await this.reloadFiles(null)
                })
                    .catch(response => {
                        Common.notifyError(null, response.response.data.messageErro);
                    });
            },
            async reloadFiles(o) {
                console.log(o);
                await PlanoFamiliaAPI.getPessoas(this.pessoa.id, this.pessoa.cpf).then(response => {
                    var pessoafake = response.data;
                    this.anexos = pessoafake.anexos;
                });
            }
        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

